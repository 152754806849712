import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { AppContext, dConfirm, tSuccess, useUser } from 'unno-comutils'
import { logout } from 'unno-comutils/connect'

import Icon from 'unno-comutils/Icon'

import { MenuGroups, Menus } from './service/menu'

export default function MainLayout (props: any) {
    const location = useLocation()

    const paths = location.pathname.split('/').filter(i => !!i)
    const cGroup = paths[0]

    const group = MenuGroups.find(i => i.page === cGroup)

    return <div className={`layout-area ${!!group ? '-' + group.color : '-blue'}`}>
        <SideMenu/>
        <div className="layout-content">
            <div className="layout-protect">
                {props.children}
            </div>
        </div>
    </div>
}

function SideMenu (props: any) {
    const user = useUser()
    const location = useLocation()
    const history = useHistory()

    const [miniSideMenu, setMiniSideMenu] = useState(localStorage.getItem('UNNO_OFFICE_SIDEMENU_MINI') === '1')

    const menuClick = (e: any, item: any) => {
        if (!e.ctrlKey) {
            const url = !!item.url ? item.url :
                (!!item.group ? `/${item.group}/${item.page}` : `/${item.page}`)
            e.preventDefault()
            history.push(url)
        }
    }

    const toggleMiniSideMenu = () => {
        const mini = !miniSideMenu
        setMiniSideMenu(mini)
        localStorage.setItem('UNNO_OFFICE_SIDEMENU_MINI', mini ? '1' : '')
    }

    const userLogout = (setUser: any) => {
        dConfirm('Logout ?').then(() => {
            logout().then(() => {
                tSuccess('ออกจากระบบแล้ว')
                setUser(null)
            })
        })
    }

    const paths = location.pathname.split('/').filter(i => !!i)
    const cGroup = paths[0]
    const cMenu = paths[1]

    const roles = user.roles

    return <div className={`layout-menu` + (props.grow ? ' -grow' : '') + (miniSideMenu ? ' -mini' : '')}>
        <div className="layout-menuhead">
            <Icon name="car-building" className="btn-main" button onClick={() => history.push(`/office/home`)}/>
            <AppContext.Consumer>
                {({ setUser }) => <Icon name={'power-off'} className={'ml-auto'} button onClick={() => userLogout(setUser)}/>}
            </AppContext.Consumer>
        </div>
        <div className="layout-menuifo">
            <span className={'_version'}>Version: {process.env.REACT_APP_VERSION}</span>
            <span className={'_user'}>{user.name}</span>
        </div>

        <div className={'layout-menumain'}>{MenuGroups
            .filter(item => roles.indexOf(item.role) >= 0 || !item.role)
            .map(item => {
                const active = cGroup === item.page
                return <div key={`group_${item.page}`} className={'_item' + (active ? ' -active' : '')} onClick={(e?: any) => menuClick(e, item)}>
                    <Icon name={item.icon} className={active ? item.color : ''} solid={active}/>
                    <div className="_label">{item.name}</div>
                </div>
            })}</div>

        <div className="layout-menuside">
            {!!cGroup && Menus
                .filter((item: any) => item.group === cGroup && (roles.indexOf(item.role) >= 0 || !item.role))
                .map((item: any) => <div key={`item_${item.group}-${item.page}`} className={'_item' + (cMenu === item.page ? ' active' : '')}>
                    <a href={!!item.url ? item.url : (!!item.group ? `/${item.group}/${item.page}` : `/${item.page}`)} onClick={e => menuClick(e, item)}>
                        {item.icon && <Icon name={item.icon} regular/>}
                        <span>{item.name}</span>
                    </a>
                </div>)}
        </div>

        <div className="btn-coll"><Icon button name={'chevron-double-' + (miniSideMenu ? 'right' : 'left')} onClick={toggleMiniSideMenu}/></div>
    </div>
}
