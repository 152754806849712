import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AppContext, get, useAppStateContext } from 'unno-comutils'
import { ToastContainer } from 'unno-comutils/Dialog'
import { checkLogin, logout } from 'unno-comutils/connect'
import Wait from 'unno-comutils/Wait'

import Routing from './service/router'
import MainLayout from './layout'

import 'unno-comutils/style/core.css'
import './style/app.scss'
import { User } from 'unno-comutils/utils'

export const GMAP_LOAD: any = { libraries: ['geometry', 'places', 'visualization'], key: process.env.REACT_APP_GMAP, language: 'th' }

export default function App () {
    const [wait, setWait] = useState(0)
    const appState = useAppStateContext()

    useEffect(() => {
        setTimeout(() => {
            checkLogin().then((d: any) => {
                if (d.ok) {
                    setWait(1)
                    appState.setUser(new User(d.user))
                    get('app/meta', { init: 1 }).then(({ ok, __time, ...data }) => {
                        if (ok) appState.setMeta(data)
                    }).finally(() => setWait(2))
                }
                else {
                    setWait(2)
                    appState.setUser(null)
                    logout()
                }
            })
        }, 200)
    }, [])

    if (wait < 2) return <Wait color={wait ? 'blue' : 'pink'}/>
    return <AppContext.Provider value={appState}>
        <div className="layout">
            <BrowserRouter>
                {!!appState.user ? <MainLayout children={<Routing/>}/> : <Routing/>}
            </BrowserRouter>
            <ToastContainer/>
        </div>
    </AppContext.Provider>
}
